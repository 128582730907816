import Convention from "models/convention-api/v1/Convention"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"

type ConventionWithTicketTypes = Convention & {
  convention_ticket_types: ConventionTicketType[]
}

const hasTicketTypes = (convention: Convention): convention is ConventionWithTicketTypes =>
  !!convention.convention_ticket_types?.length

export default hasTicketTypes
