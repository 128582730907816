import { Box, styled, Typography } from "@mui/material"
import { PaymentIntent } from "@stripe/stripe-js"
import moment from "moment-timezone"

interface OrderDetailsProps {
  paymentIntent: PaymentIntent
}

const OrderDetails = (props: OrderDetailsProps) => {
  const { paymentIntent } = props

  // Time at which the object was created.
  // Measured in seconds since the Unix epoch.
  // So we need to multiply it by 1000 to get the correct date from moment.
  const created = moment(paymentIntent.created * 1000)
  return (
    <_Container>
      <Typography component="p" variant="paragraphRegular" color="gray.dark">
        Order ID: {paymentIntent.id}
      </Typography>
      <Typography
        component="time"
        dateTime={created.format()}
        variant="paragraphRegular"
        color="gray.dark">
        Order Date: {created.format("MMM DD, YYYY")}
      </Typography>
    </_Container>
  )
}

export default OrderDetails

const _Container = styled(Box)(() => ({}))
