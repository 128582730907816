import { Typography } from "@mui/material"
import { REGISTRATION_COUNTDOWN } from "app-constants/constants"
import moment from "moment-timezone"
import pluralize from "pluralize"
import { useEffect, useMemo } from "react"
import { pxToRem } from "utils/helpers"
import useNow from "hooks/useNow"

interface DialogRegistrationTimerProps {
  onFinish: () => void
}

const DialogRegistrationTimer = (props: DialogRegistrationTimerProps) => {
  const { onFinish } = props

  const now = useNow({precisionInMs: 450})
  const nowWithMoment = moment(now.value)
  const endAt = useMemo(() => moment().add(REGISTRATION_COUNTDOWN, 'milliseconds'), [])

  const timeLeft = moment(endAt.diff(nowWithMoment))
  const minutesLeft = timeLeft.minutes()
  const secondsLeft = timeLeft.seconds()
  const noTimeLeft = timeLeft.valueOf() <= 0

  useEffect(() => {
    if (!noTimeLeft) return
    onFinish()
  }, [noTimeLeft])

  const pluralizedTimeLeft = (() => {
    if (minutesLeft) return pluralize("minute", minutesLeft)
    return pluralize("second", secondsLeft)
  })()

  if (noTimeLeft) return <></>

  return (
    <Typography component="p" variant="paragraphRegular" marginTop={pxToRem(10)}>
      <strong>{timeLeft.format("mm:ss")}</strong> {pluralizedTimeLeft} left to complete your
      purchase.
    </Typography>
  )
}

export default DialogRegistrationTimer
