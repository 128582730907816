import { useEffect } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"

const useSubscribeChannel = (
  websocket: ReturnType<typeof useWebSocket>,
  identifier: string | null,
) => {
  const isWebsocketOpen = ReadyState.OPEN === websocket.readyState

  useEffect(() => {
    if (!isWebsocketOpen) return
    if (identifier === null) return
    websocket.sendJsonMessage({
      identifier,
      command: "subscribe",
    })
  }, [websocket.readyState, identifier])
}

export default useSubscribeChannel
