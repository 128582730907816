import { ConventionLocationStyle } from "app-constants/constants"
import useNow from "hooks/useNow"
import Convention from "models/convention-api/v1/Convention"
import moment from "moment-timezone"
import { useEffect, useState } from "react"

const useIsConventionClosedForRegistration = (convention?: Convention) => {
  const now = useNow({
    precisionInMs: 1000,
  })

  const [isClosed, setIsClosed] = useState<null | boolean>(null)

  const getConventionNow = (convention: Convention) => moment(now.value).tz(convention.time_zone)
  const getConventionStartAt = (convention: Convention) =>
    moment(convention.start_at).clone().tz(convention.time_zone)
  const getConventionEndAt = (convention: Convention) =>
    moment(convention.end_at).clone().tz(convention.time_zone)

  const checkIfClosed = (convention: Convention): boolean | null => {
    if (convention.location_style === ConventionLocationStyle.VIRTUAL)
      return checkIfVirtualClosed(convention)
    if (convention.location_style === ConventionLocationStyle.INPERSON)
      return checkIfInPersonClosed(convention)

    return null
  }

  const checkIfInPersonClosed = (convention: Convention) => {
    const now = getConventionNow(convention)
    const startAt = getConventionStartAt(convention)
    const endAt = getConventionEndAt(convention)
    const closeRegistrationsBeforeDays = convention.close_registrations_before_days

    if (closeRegistrationsBeforeDays === null) return now.isAfter(endAt)
    if (closeRegistrationsBeforeDays === undefined) return now.isAfter(endAt)
    if (Number(closeRegistrationsBeforeDays) === 0) return now.isAfter(startAt)

    const closeRegistrationAt = startAt
      .clone()
      .subtract(Number(closeRegistrationsBeforeDays), "days")

    return now.isAfter(closeRegistrationAt)
  }

  const checkIfVirtualClosed = (convention: Convention) => {
    const now = getConventionNow(convention)
    const endAt = moment(convention.end_at).tz(convention.time_zone)

    return now.isAfter(endAt)
  }

  useEffect(() => {
    if (!convention) return

    setIsClosed(checkIfClosed(convention))
  }, [
    now.value,
    convention?.start_at,
    convention?.time_zone,
    convention?.close_registrations_before_days,
  ])

  return isClosed
}

export default useIsConventionClosedForRegistration
