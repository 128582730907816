import { Box, Typography } from "@mui/material"
import { MomentFormats } from "app-constants/constants"
import Convention from "models/convention-api/v1/Convention"
import moment from "moment-timezone"
import { pxToRem } from "utils/helpers"

const DialogConventionInformation = ({ convention }: { convention: Convention }) => {
  const startAt = moment(convention.start_at)
  const endAt = moment(convention.end_at)
  return (
    <Box>
      <Typography component="h4" variant="header5">
        {convention.name}
      </Typography>
      <Typography component="p" variant="paragraphRegular" marginTop={pxToRem(10)} gap={pxToRem(5)}>
        {startAt.format(MomentFormats.startTime)}
        <span aria-hidden="true" style={{ margin: `0 ${pxToRem(5)}` }}>
          &#183;
        </span>
        {endAt.format(MomentFormats.endTime)}
      </Typography>
    </Box>
  )
}

export default DialogConventionInformation
