import ResourceModel from "models/convention-api/ResourceModel"
import { Moment } from "moment-timezone"

export default class ConventionTicketType extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_ticket_types"

  convention_convention_id!: string
  name!: string
  cost_usd_cents!: number
  end_at!: Moment
}
