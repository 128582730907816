import { ConventionRegistrationState, StripePaymentStatus } from "app-constants/constants"
import hasTicketTypes from "lib/conventions/hasTicketTypes"
import isConventionPaid from "lib/conventions/isPaid"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import { useEffect, useState } from "react"
import { percentageDifference } from "utils/helpers"

interface UseConventionRegistrationStateProps {
  isClosedForRegistration: boolean | null
  convention: Convention
  conventionPeopleCount: number | null
  conventionRegistration?: ConventionRegistration | null
  paymentStatus: StripePaymentStatus | null
  isLoadingConventionRegistration: boolean
  activeTicketType: null | ConventionTicketType
}

const useConventionRegistrationState = (props: UseConventionRegistrationStateProps) => {
  const {
    isClosedForRegistration,
    convention,
    conventionPeopleCount,
    conventionRegistration,
    isLoadingConventionRegistration,
    activeTicketType,
    paymentStatus,
  } = props

  const current = useCurrent()
  const isUserLoggedIn = !!current?.user?.id
  const isPaid = isConventionPaid(convention)

  const [state, setState] = useState<ConventionRegistrationState>(
    ConventionRegistrationState.LOADING,
  )

  useEffect(() => {
    const isUserRegisteredForConvention = !!conventionRegistration
    const registrationUnpaid = paymentStatus !== StripePaymentStatus.SUCCEEDED

    const paymentInProgress = paymentStatus === StripePaymentStatus.PROCESSING

    if (paymentInProgress) {
      setState(ConventionRegistrationState.PROCESSING_PAYMENT)
      return
    }

    if (isPaid && isUserRegisteredForConvention && registrationUnpaid) {
      setState(ConventionRegistrationState.READY_FOR_REGISTRATION)
      return
    }

    if (isUserLoggedIn && isUserRegisteredForConvention && (isPaid || hasTicketTypes(convention))) {
      setState(ConventionRegistrationState.REGISTERED_PAID)
      return
    }

    if (isUserLoggedIn && isUserRegisteredForConvention) {
      setState(ConventionRegistrationState.REGISTERED)
      return
    }

    if (isPaid && !activeTicketType) {
      setState(ConventionRegistrationState.CLOSED)
      return
    }

    if (isClosedForRegistration !== null && isClosedForRegistration) {
      setState(ConventionRegistrationState.CLOSED)
      return
    }

    if (
      convention.registration_capacity &&
      conventionPeopleCount &&
      convention.registration_capacity === conventionPeopleCount
    ) {
      setState(ConventionRegistrationState.AT_CAPACITY)
      return
    }

    // 10% or less of capacity is left
    if (
      typeof conventionPeopleCount === "number" &&
      typeof convention.registration_capacity === "number" &&
      // for some reason TS doesn't see typeof and throws an error
      // @ts-ignore
      percentageDifference(convention.registration_capacity, conventionPeopleCount) <= 10
    ) {
      setState(ConventionRegistrationState.FEW_SPOTS_LEFT)
      return
    }

    if (!current) {
      setState(ConventionRegistrationState.LOADING)
      return
    }

    if (isLoadingConventionRegistration) {
      setState(ConventionRegistrationState.LOADING)
      return
    }

    if (!isUserLoggedIn) {
      setState(ConventionRegistrationState.READY_FOR_REGISTRATION)
      return
    }

    if (isUserLoggedIn && !isUserRegisteredForConvention) {
      setState(ConventionRegistrationState.READY_FOR_REGISTRATION)
      return
    }
  }, [
    current,
    isLoadingConventionRegistration,
    conventionRegistration,
    convention.convention_registration_capacity,
    isClosedForRegistration,
    conventionPeopleCount,
    isUserLoggedIn,
    isPaid,
    activeTicketType,
    paymentStatus,
  ])

  return {
    state,
    setState,
    isLoading: state === ConventionRegistrationState.LOADING,
    isReadyForRegistration: state === ConventionRegistrationState.READY_FOR_REGISTRATION,
    isRegistered:
      state === ConventionRegistrationState.REGISTERED ||
      state === ConventionRegistrationState.REGISTERED_PAID,
    isClosed: state === ConventionRegistrationState.CLOSED,
    isAtCapacity: state === ConventionRegistrationState.AT_CAPACITY,
  }
}

export default useConventionRegistrationState
