import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { NavLink, NavLinkProps } from "components/navigation/NavLink"
import React from "react"

interface TopNavigationEmbedHorizontalMenuProps {
  className?: string
  menuItems: NavLinkProps[]
}

const TopNavigationEmbedHorizontalMenu = (props: TopNavigationEmbedHorizontalMenuProps) => {
  const { className, menuItems } = props

  return (
    <_Container className={className}>
      <Box display="flex" flexDirection="row">
        {menuItems.map((menuItem) => (
          <_NavLinkContainer key={menuItem.href}>
            <NavLink {...menuItem} />
          </_NavLinkContainer>
        ))}
      </Box>
    </_Container>
  )
}

export default TopNavigationEmbedHorizontalMenu

const _Container = styled(Box)({
  display: "flex",
  flexFlow: "row nowrap",
  marginLeft: 0,
  width: "auto",
})

const _NavLinkContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "24px",
})
