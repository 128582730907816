import { ConventionBooleanSetting } from "app-constants/constants"
import Convention from "models/convention-api/v1/Convention"

const isPaid = (convention: Convention): boolean => {
  if (!convention.convention_booleans?.length) return false

  const foundSetting = convention.convention_booleans.find(
    ({ setting, value }) => setting === ConventionBooleanSetting.PAID && value,
  )

  return !!foundSetting
}

export default isPaid
