import { Typography } from "@mui/material"
import { PaymentIntent } from "@stripe/stripe-js"
import OrderDetails from "components/OrderDetails"
import TotalPrice from "components/common/TotalPrice"
import Dialog from "components/conventions/registration/dialogs/Dialog"
import DialogContent from "components/conventions/registration/dialogs/DialogContent"
import DialogConventionInformation from "components/conventions/registration/dialogs/DialogConventionInformation"
import DialogHeader from "components/conventions/registration/dialogs/DialogHeader"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"

interface ConfirmationPaymentDialogProps {
  convention: Convention
  conventionRegistration: ConventionRegistration
  paymentIntent: PaymentIntent
  onClose: () => void
}

const ConfirmationPaymentDialog = (props: ConfirmationPaymentDialogProps) => {
  const { convention, conventionRegistration, paymentIntent, onClose } = props

  return (
    <Dialog open>
      <DialogHeader onClose={onClose} />
      <DialogContent>
        <OrderDetails paymentIntent={paymentIntent} />
        <DialogConventionInformation convention={convention} />
        <TotalPrice
          amountOfUnits={conventionRegistration.people_count!}
          pricePerUnit={conventionRegistration.ticket_type?.cost_usd_cents!}
        />
        <Typography component="p" variant="paragraphRegular">
          Your tickets have been sent to your email address. You can also access them on Event page.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationPaymentDialog
