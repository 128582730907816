const NUMBER_FORMAT_LOCALE = "en-US"

const NUMBER_FORMAT_CONFIG = {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
}

const getFormattedPrice = (priceInCents: number) =>
  new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, NUMBER_FORMAT_CONFIG)
    // Need to divide by 100 to transform the price from cents to dollars
    .format(priceInCents / 100) + " USD"

export default getFormattedPrice
