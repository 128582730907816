export default {
  /**
  Pushes registered conventions
  */
  pushConventionRegistrations(ids: string[]) {
    window.dataLayer.push({
      event: "conventionRegistered",
      numConventions: ids.length,
      conventionIds: ids.join(","),
    })
  },
}
