import { ConventionLocationStyle } from "app-constants/constants"
import ResourceModel from "models/convention-api/ResourceModel"
import ConventionBoolean from "models/convention-api/v1/ConventionBoolean"
import ConventionRegistrationCapacity from "models/convention-api/v1/ConventionRegistrationCapacity"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import { Moment } from "moment-timezone"
import ConventionSessionCount from 'models/convention-api/v1/ConventionSessionCount';

export default class Convention extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/conventions"

  protected static rels = {
    convention_registration_capacity: () => ({
      type: ConventionRegistrationCapacity,
      from: "id",
      filterOn: "id",
    }),
    convention_ticket_types: () => ({
      type: ConventionTicketType,
      from: "id",
      filterOn: "convention_convention_id",
    }),
    convention_booleans: () => ({
      type: ConventionBoolean,
      from: "id",
      filterOn: "convention_convention_id",
    }),
    convention_session_count: () => ({
      type: ConventionSessionCount,
      from: 'id',
      filterOne: "convention_id",
    }),
  }

  details!: string
  end_at!: Moment
  hero_image_url!: string
  location_style!: ConventionLocationStyle
  logo_url!: string
  name!: string
  start_at!: Moment
  time_zone!: string

  address_street!: null | string
  address_street2!: null | string
  city!: null | string
  country_id!: null | number
  latitude!: null | number
  location_instructions!: null | string
  location_name!: null | string
  longitude!: null | number
  state!: null | string
  zipcode!: null | string
  max_people_per_registration!: null | number
  close_registrations_before_days!: null | number
  qr_code!: null | boolean
  registration_capacity!: null | number
  automatically_generated!: boolean

  convention_registration_capacity?: ConventionRegistrationCapacity
  convention_ticket_types?: ConventionTicketType[]
  convention_booleans?: ConventionBoolean[]
  convention_session_count?: ConventionSessionCount
}
