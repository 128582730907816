import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { styled } from "@mui/material"
import React from "react"

const DialogButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(function DialogButton(
  props,
  ref,
) {
  return (
    <_LoadingButton ref={ref} variant="contained" color="primary" children="Register" {...props} />
  )
})

export default DialogButton

export const _LoadingButton = styled(LoadingButton)({
  width: "100%",
}) as unknown as typeof LoadingButton
