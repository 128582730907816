import { Dialog as MUIDialog, styled } from "@mui/material"
import { pxToRem } from "utils/helpers"

const Dialog = styled(MUIDialog)(({ theme }) => ({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    position: "relative",

    width: "100%",
    minWidth: pxToRem(300),
    maxWidth: pxToRem(455),

    borderRadius: pxToRem(10),

    backgroundColor: theme.palette.gray.lightest,
    boxShadow: `0 ${pxToRem(4)} ${pxToRem(4)} rgba(0, 0, 0, 0.25), 0 0 ${pxToRem(
      4,
    )} rgba(0, 0, 0, 0.12)`,

    overflow: "hidden",
  },
})) as typeof MUIDialog

export default Dialog
