import ConventionCancelRegistrationDialog from "components/conventions/registration/dialogs/ConventionCancelRegistrationDialog"
import useAuthRedirect from "hooks/useAuthRedirect"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import React, { useState } from "react"

interface OnCancelPayload {
  convention: Convention
  conventionRegistration: ConventionRegistration
}

interface OpenDialogPayload {
  convention: Convention
  conventionRegistration: ConventionRegistration
}

const useCancelRegistrationDialog = (onCancel: (payload: OnCancelPayload) => void) => {
  const authRedirect = useAuthRedirect()
  const [convention, setConvention] = useState<Convention | null>(null)
  const [conventionRegistration, setConventionRegistration] =
    useState<ConventionRegistration | null>(null)

  const handleClose = () => {
    setConvention(null)
    setConventionRegistration(null)
  }

  const handleCancel = (payload: OnCancelPayload) => {
    setConvention(null)
    setConventionRegistration(null)
    onCancel(payload)
  }

  const openDialog = (payload: OpenDialogPayload) =>
    authRedirect(() => {
      setConvention(payload.convention)
      setConventionRegistration(payload.conventionRegistration)
    })

  return {
    openDialog,
    Dialog: convention && conventionRegistration && (
      <ConventionCancelRegistrationDialog
        convention={convention}
        conventionRegistration={conventionRegistration}
        onClose={handleClose}
        onCancel={handleCancel}
      />
    ),
  }
}

export default useCancelRegistrationDialog
