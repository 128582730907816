import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"
import { Current } from "lib/use-current"
import ConventionRegistrationPaymentIntent from "models/convention-api/v1/ConventionRegistrationPaymentIntent"
import { getCurrentProviderId } from "utils/helpers"

interface Props {
  current: Current
  conventionRegistrationId: string
}

const postConventionRegistrationPaymentIntent = async (props: Props) => {
  const { current, conventionRegistrationId } = props

  const providerId = getCurrentProviderId(current)
  const url = `/v1/convention_providers/${providerId}/current_user/convention_registrations/purchase_refreshes`

  const apiResponse = await sendConventionApiRequest({
    current,
    url,
    method: "POST",
    body: {
      data: {
        type: "purchase_refreshes",
        attributes: {
          convention_registration_id: conventionRegistrationId,
        },
      },
    },
  })

  return processApiResponse<ConventionRegistrationPaymentIntent>(apiResponse)
}

export default postConventionRegistrationPaymentIntent
