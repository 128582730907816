import FlareIcon from "@mui/icons-material/Flare"
import HomeIcon from "@mui/icons-material/Home"
import ViewComfyIcon from "@mui/icons-material/ViewComfy"
import ViewCompactIcon from "@mui/icons-material/ViewCompact"
import { Box, Theme } from "@mui/material"
import { useMediaQuery } from "@mui/material"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/system"
import { ConventionLocationStyle, NAVIGATION_HEIGHT } from "app-constants/constants"
import { createPath, ROUTE } from "app-constants/routing"
import AccountLink from "components/account/AccountLink"
import LeftNavigationMenu, {
  LeftNavigationMenuItem,
} from "components/navigation/LeftNavigationMenu"
import { NavLinkProps } from "components/navigation/NavLink"
import TopNavigationContainer from "components/navigation/TopNavigationContainer"
import TopNavigationEmbedHorizontalMenu from "components/navigation/TopNavigationEmbedHorizontalMenu"
import TopNavigationHamburgerMenu from "components/navigation/TopNavigationHamburgerMenu"
import TopNavigationVerticalMenu from "components/navigation/TopNavigationVerticalMenu"
import { useExhibitorCopyText } from "hooks/exhibitors/useExhibitorsCopyText"
import { useUser } from "hooks/useUser"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import { useRouter } from "next/router"
import pluralize from "pluralize"
import React, { useMemo } from "react"

interface TopMicroNavigationProps {
  convention: Convention
  stageEnabled: boolean
}

const TopMicroEmbedNavigation = (props: TopMicroNavigationProps) => {
  const { stageEnabled, convention } = props
  const sessionEnabled = convention!.location_style !== ConventionLocationStyle.INPERSON
  const exhibitorsCopyText = useExhibitorCopyText()
  const current = useCurrent()
  const user = useUser()
  const providerConfig = current?.config
  const router = useRouter()

  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))

  const conventionId = router.query.convention_id as string

  const generalNavLinks: NavLinkProps[] = useMemo(
    () => [
      {
        href: createPath({ path: ROUTE.EMBED_CONVENTIONS }),
        text: providerConfig?.expo_copy_text ?? "Conventions",
      },
      {
        href: createPath({ path: ROUTE.EMBED_EXHIBITORS }),
        text: exhibitorsCopyText!.plural,
      },
      ...(providerConfig?.people_index
        ? [
            {
              href: createPath({ path: ROUTE.EMBED_PEOPLE }),
              text: "People",
            },
          ]
        : []),
      {
        href: createPath({ path: ROUTE.EMBED_VIDEOS }),
        text: "Videos",
      },
    ],
    [providerConfig?.expo_copy_text, providerConfig?.people_index, exhibitorsCopyText],
  )

  const leftMenuItems: LeftNavigationMenuItem[] = [
    {
      path: createPath({ path: ROUTE.EMBED_CONVENTION, params: { conventionId } }),
      label: providerConfig.nav_launchpad_copy_text,
      Icon: HomeIcon,
    },
    {
      path: createPath({ path: ROUTE.EMBED_CONVENTION_STAGE, params: { conventionId } }),
      label: providerConfig.nav_stage_copy_text,
      isEnabled: stageEnabled,
      Icon: FlareIcon,
    },
    {
      path: createPath({ path: ROUTE.EMBED_CONVENTION_SESSIONS, params: { conventionId } }),
      label: pluralize(providerConfig.session_copy_text),
      isEnabled: sessionEnabled,
      Icon: ViewCompactIcon,
    },
    {
      path: createPath({ path: ROUTE.EMBED_CONVENTION_EXHIBITORS, params: { conventionId } }),
      label: exhibitorsCopyText?.plural,
      Icon: ViewComfyIcon,
    },
  ]

  if (isWideScreen) {
    return (
      <>
        <NavContent
          generalNavLinks={generalNavLinks}
          authNavLinks={undefined}
          leftMenuItemsHidden={convention.automatically_generated}
          leftMenuItems={leftMenuItems}
          isWideScreen={isWideScreen}
          user={user}
          conventionName={convention.name}
        />
        {!convention.automatically_generated && (
        <_LeftNavigation component="nav">
          <_LeftNavigationContainer role="region" aria-label="Left Navigation">
            <_LeftNavigationConventionName component="h3">
              {convention.name}
            </_LeftNavigationConventionName>
              <_LeftNavigationMenu menuItems={leftMenuItems} />
          </_LeftNavigationContainer>
          <_LeftNavigationRightDivider aria-hidden={true} />
          <_LeftNavigationBackground aria-hidden={true} />
        </_LeftNavigation>
        )}
      </>
    )
  }

  return (
    <NavContent
      generalNavLinks={generalNavLinks}
      authNavLinks={undefined}
      leftMenuItems={leftMenuItems}
      leftMenuItemsHidden={convention.automatically_generated}
      isWideScreen={isWideScreen}
      user={user}
      conventionName={convention.name}
    />
  )
}

export default TopMicroEmbedNavigation

interface NavigationContentProps {
  generalNavLinks: NavLinkProps[]
  authNavLinks: NavLinkProps[]
  leftMenuItemsHidden?: boolean
  leftMenuItems: LeftNavigationMenuItem[]
  isWideScreen: boolean
  user: any
  conventionName: string
}

const NavContent = (props: NavigationContentProps) => {
  const { generalNavLinks, user } = props

  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))

  return (
    <TopNavigationContainer showDivider={false}>
      <_ContainerBox>
        {!isWideScreen && (
          <TopNavigationHamburgerMenu menuLabel="Menu" icon={"ListAlt"}>
            <TopNavigationVerticalMenu menuItems={generalNavLinks} />
          </TopNavigationHamburgerMenu>
        )}

        {isWideScreen && (
          <_MainNavigationContainer>
            <_TopNavigationEmbedHorizontalAuthMenu menuItems={generalNavLinks} />
          </_MainNavigationContainer>
        )}
        {user && <AccountLink path={ROUTE.EMBED_ACCOUNT_MAIN} />}
      </_ContainerBox>
    </TopNavigationContainer>
  )
}

const _ConventionName = styled(Typography)(({ theme }) => ({
  margin: "10px 0 25px 20px",
  padding: 0,
  ...theme.fonts.lato400,
  fontSize: "20px",
  lineHeight: "24px",
})) as typeof Typography

const _LeftNavigationConventionName = styled(_ConventionName)({
  margin: "40px 0 0 0",
  padding: "0 41px 0 41px",
  width: "100%",
}) as typeof Typography

const _LeftNavigation = styled(Box)({
  pointerEvents: "auto",
  alignItems: "stretch",
  flexBasis: "auto",
  flexDirection: "column",
  flexShrink: 0,
  height: "100vh",
  position: "fixed",
  zIndex: 49,
  width: "250px",
})

const _LeftNavigationBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: -NAVIGATION_HEIGHT,
  right: 0,
  bottom: 0,
  left: "-100vh",
  backgroundColor: theme.palette.gray.lightest,
  zIndex: -1,
}))

const _LeftNavigationContainer = styled(Box)({
  display: "flex",
  flexFlow: "nowrap column",
  alignItems: "center",
  width: "100%",
  height: "100%",
})

const _LeftNavigationRightDivider = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "calc(100vh + 100%)",
  top: -NAVIGATION_HEIGHT,
  right: 0,
  bottom: 0,
  borderRight: `1px solid ${theme.palette.gray.light}`,
}))

const _LeftNavigationMenu = styled(LeftNavigationMenu)({
  width: "100%",
  marginTop: "40px",
})

const _ContainerBox = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  flexFlow: "nowrap row",
  justifyContent: "space-between",
  alignItems: "center",
})

const _TopNavigationEmbedHorizontalAuthMenu = styled(TopNavigationEmbedHorizontalMenu)({
  marginLeft: "32px",
  ".MuiButton-root.MuiButton-root": {
    borderRadius: "10px",
  },
})

const _MainNavigationContainer = styled(_ContainerBox)({
  marginLeft: "0",
  width: "auto",
})
