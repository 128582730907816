import { Elements } from "@stripe/react-stripe-js"
import { PaymentIntent } from "@stripe/stripe-js"
import { PaymentIntentResult } from "@stripe/stripe-js/types/stripe-js/stripe"
import {
  DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION,
  ConventionRegistrationStatus,
  StripePaymentStatus,
  CONVENTION_MAX_PEOPLE_PER_REGISTRATION,
} from "app-constants/constants"
import assert from "assert"
import TotalPrice from "components/common/TotalPrice"
import TicketTypeCard from "components/conventions/TicketTypeCard"
import Dialog from "components/conventions/registration/dialogs/Dialog"
import DialogAdditionalText from "components/conventions/registration/dialogs/DialogAdditionalText"
import DialogButton from "components/conventions/registration/dialogs/DialogButton"
import DialogContent from "components/conventions/registration/dialogs/DialogContent"
import DialogConventionInformation from "components/conventions/registration/dialogs/DialogConventionInformation"
import DialogHeader from "components/conventions/registration/dialogs/DialogHeader"
import DialogPayment from "components/conventions/registration/dialogs/DialogPayment"
import DialogRegistrationTimer from "components/conventions/registration/dialogs/DialogRegistrationTimer"
import DialogTickets from "components/conventions/registration/dialogs/DialogTickets"
import {
  CONVENTION_REGISTRATION_ERROR_MESSAGES,
  ConventionRegistrationError,
} from "errors/ConventionRegistrationError"
import useActiveTicketType from "hooks/conventions/useActiveTicketType"
import { useBoolean } from "hooks/useBoolean"
import useShowSnackbar from "hooks/useShowSnackbar"
import useStripeLoader from "hooks/useStripeLoader"
import isPaid from "lib/conventions/isPaid"
import getConventionRegistration from "lib/getConventionRegistration"
import postConventionRegistration from "lib/postConventionRegistration"
import postConventionRegistrationPaymentIntent from "lib/postConventionRegistrationPaymentIntent"
import postConventionRegistrationsPurchaseRefreshes from "lib/postConventionRegistrationsPurchaseRefreshes"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import ConventionRegistrationPaymentIntent from "models/convention-api/v1/ConventionRegistrationPaymentIntent"
import React, { useEffect, useState } from "react"
import { assertCurrent } from "utils/asserts"

interface Payload {
  convention: Convention
  conventionRegistration: ConventionRegistration
  paymentIntent: PaymentIntent | null
}

interface ConventionInPersonRegistrationDialogProps {
  convention: Convention
  conventionPeopleCount: number | null
  onCancel: () => void
  onRegister: (payload: Payload) => void
}

const ConventionInPersonRegistrationDialog = (props: ConventionInPersonRegistrationDialogProps) => {
  const {
    onCancel,
    onRegister,
    convention,
    conventionPeopleCount: defaultConventionPeopleCount,
  } = props

  const paid = isPaid(convention)

  const loading = useBoolean(false)
  const current = useCurrent()
  const timerReset = useBoolean(false)
  const showSnackbar = useShowSnackbar()
  const [registeredPeopleCount, setRegisteredPeopleCount] = useState(
    DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION,
  )

  const conventionRegistration = ConventionRegistration.useOne({
    filter: {
      convention_id: convention.id,
    },
    includes: ["ticket_type", "payment_intent"],
  })

  const stripeLoader = useStripeLoader()

  const activeTicketType = useActiveTicketType(convention, conventionRegistration.data)

  const conventionRegistrationLoading =
    !conventionRegistration.data && conventionRegistration.isValidating
  const dialogLoading = loading.value || conventionRegistrationLoading || stripeLoader.loading

  const handleClose = () => {
    if (dialogLoading) return
    onCancel()
  }

  const handleFinishTimer = () => {
    showSnackbar("Your time is up!", "error")
    onCancel()
  }

  const resetTimer = async () => {
    try {
      assertCurrent(current)
      assert(conventionRegistration.data?.id, "conventionRegistrationId is not found")
      await postConventionRegistrationsPurchaseRefreshes({
        current,
        conventionRegistrationId: conventionRegistration.data?.id,
      })
      timerReset.on()
    } catch (error) {
      console.error(error)
      handleFinishTimer()
    }
  }

  useEffect(() => {
    if (!conventionRegistration.data?.id) return
    if (timerReset.value) return

    resetTimer()
  }, [conventionRegistration.data?.id])

  const handleRegister = async () => {
    loading.on()
    try {
      assertCurrent(current)

      const conventionId = convention.id
      const newConventionRegistration = conventionRegistration.data
        ? conventionRegistration.data
        : await postConventionRegistration({
            current,
            conventionId,
            peopleCount: registeredPeopleCount,
          })

      if (!paid) {
        showSnackbar("Registered successfully", "success")
        onRegister({
          convention,
          conventionRegistration: newConventionRegistration,
          paymentIntent: null,
        })
        return
      }

      const paymentIntent = await postConventionRegistrationPaymentIntent({
        conventionRegistrationId: newConventionRegistration.id,
        current,
      })

      const lockedConventionRegistration = await getConventionRegistration({
        conventionId,
        current,
      })
      lockedConventionRegistration.ticket_type = convention.convention_ticket_types?.find(
        (ticketType) => ticketType.id === newConventionRegistration.convention_ticket_type_id,
      )
      lockedConventionRegistration.payment_intent = paymentIntent
      await conventionRegistration.mutate(lockedConventionRegistration)
    } catch (error) {
      console.error(error)

      const message =
        error instanceof ConventionRegistrationError
          ? error.message
          : CONVENTION_REGISTRATION_ERROR_MESSAGES.CANNOT_BE_COMPLETED

      showSnackbar(message, "error")
    } finally {
      loading.off()
    }
  }

  const paymentIntent: ConventionRegistrationPaymentIntent | null = timerReset
    ? conventionRegistration.data?.payment_intent ?? null
    : null

  useEffect(() => {
    if (!paymentIntent) return
    if (stripeLoader.loading) return
    if (stripeLoader.loaded) return

    stripeLoader.load(paymentIntent.public_key, {
      stripeAccount: paymentIntent.account_identifier,
    })
  }, [stripeLoader.loading, stripeLoader.loaded, paymentIntent?.id])

  const handlePay = (result: PaymentIntentResult) => {
    if (result.error) {
      console.error(result.error)
      showSnackbar(result.error?.message ?? "Failed to pay!", "error")
      return
    }

    showSnackbar("Registered successfully", "success")

    if (conventionRegistration.data?.payment_intent?.status) {
      conventionRegistration.data.payment_intent.status = result.paymentIntent
        .status as StripePaymentStatus
    }
    onRegister({
      convention,
      conventionRegistration: conventionRegistration.data!,
      paymentIntent: result.paymentIntent,
    })
  }

  useEffect(() => {
    if (!conventionRegistration.data) return
    if (conventionRegistration.data.payment_status === ConventionRegistrationStatus.PAID) {
      showSnackbar("You have been already registered for " + convention.name, "error")
      onCancel()
    }
  }, [conventionRegistration.data])

  const registrationCapacity = convention.registration_capacity
  const maxPerRegistration =
    convention.max_people_per_registration ?? CONVENTION_MAX_PEOPLE_PER_REGISTRATION
  const conventionPeopleCount = defaultConventionPeopleCount ?? 0

  const ticketsLeft = (() => {
    if (registrationCapacity === null) return CONVENTION_MAX_PEOPLE_PER_REGISTRATION

    return registrationCapacity - conventionPeopleCount
  })()

  const maxTickets = (() => {
    if (ticketsLeft === 0) return DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION
    if (maxPerRegistration == null) return CONVENTION_MAX_PEOPLE_PER_REGISTRATION
    if (maxPerRegistration > ticketsLeft) return ticketsLeft
    return maxPerRegistration
  })()

  const amountOfTickets = conventionRegistration.data?.people_count ?? registeredPeopleCount

  return (
    <Dialog open onClose={handleClose}>
      <DialogHeader onClose={handleClose} closeButtonDisabled={dialogLoading} />
      <DialogContent>
        <DialogConventionInformation convention={convention} />
        {!conventionRegistrationLoading && activeTicketType && (
          <TicketTypeCard ticketType={activeTicketType} />
        )}
        <DialogTickets
          ticketsLeft={ticketsLeft}
          disabled={
            ticketsLeft <= 0 || dialogLoading || !!conventionRegistration.data || !!paymentIntent
          }
          minTickets={DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION}
          maxTickets={maxTickets}
          tickets={amountOfTickets}
          onChangeTickets={(event) => setRegisteredPeopleCount(+event.target.value)}
          ticketsLeftMessage={registrationCapacity !== null}
        />
        {!conventionRegistrationLoading && activeTicketType && (
          <TotalPrice
            amountOfUnits={amountOfTickets}
            pricePerUnit={activeTicketType.cost_usd_cents}
          />
        )}
        <DialogAdditionalText />
        {paymentIntent && <DialogRegistrationTimer onFinish={handleFinishTimer} />}
        {!paymentIntent && <DialogButton onClick={handleRegister} loading={dialogLoading} />}

        {paymentIntent && stripeLoader.stripe && (
          <Elements
            stripe={stripeLoader.stripe}
            options={{
              clientSecret: paymentIntent.client_secret,
            }}>
            <DialogPayment convention={convention} loading={loading} onPayment={handlePay} />
          </Elements>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ConventionInPersonRegistrationDialog
