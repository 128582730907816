import { Box, styled, Typography } from "@mui/material"
import React from "react"
import getFormattedPrice from "utils/getFormattedPrice"
import { pxToRem } from "utils/helpers"

interface TotalPriceProps {
  amountOfUnits: number
  pricePerUnit: number
}

const TotalPrice = (props: TotalPriceProps) => {
  const { amountOfUnits, pricePerUnit } = props

  const totalPrice = amountOfUnits * pricePerUnit

  return (
    <_Container>
      <Typography variant="header6" marginRight="auto">
        Total
      </Typography>
      <Typography variant="paragraphMuted" color="gray.darker">
        {amountOfUnits} &#215; {getFormattedPrice(pricePerUnit)}
      </Typography>
      <Typography variant="paragraphRegular">{getFormattedPrice(totalPrice)}</Typography>
    </_Container>
  )
}

export default TotalPrice

const _Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  gap: pxToRem(60),
})) as typeof Box
