import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import useNow from "hooks/useNow"
import moment from "moment-timezone"
import pluralize from "pluralize"
import { useRef } from "react"
import { MouseEvent } from "react"
import { MutableRefObject } from "react"
import { makeStyles } from "tss-react/mui-compat"

const oneSecond = 1
const oneMinute = 60 * oneSecond
const oneHour = 60 * oneMinute
const oneDay = 24 * oneHour
const oneYear = 365 * oneDay

interface Props {
  mode: "unregistered" | "registering" | "registered" | "loading"
  startAt: moment.Moment | Date | number
  onRegister: () => void
  isRegisterButtonVisible: boolean
}

// interface StyleProps extends Props {
//   rootRef: MutableRefObject<HTMLDivElement>
//   buttonRef: MutableRefObject<HTMLButtonElement>
//   isStarted: boolean
// }

const useStyles = makeStyles<{
  mode: "unregistered" | "registering" | "registered" | "loading"
  startAt: moment.Moment | Date | number
  onRegister: () => void
  isRegisterButtonVisible: boolean,
  rootRef: MutableRefObject<HTMLDivElement>
  buttonRef: MutableRefObject<HTMLButtonElement>
  isStarted: boolean
}>()(
  (theme, props) => ({
    wrapper: {
      overflow: "hidden",
      height: props.isStarted ? 0 : (props.rootRef.current?.offsetHeight || "auto"),
      transition: theme.transitions.create(["height"], {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeOut,
      }),
    },
    root: {
      backgroundColor: theme.appComponents.conventionCountdown.backgroundColor,
      padding: "29px 40px",
      paddingBottom: (props.mode === "registered" ? 29 : 70),
      position: "relative",
      transition: theme.transitions.create(["padding-bottom"], {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
      [theme.breakpoints.up(600)]: {
        paddingBottom: 29,
      },
    },
    text: {
      ...theme.fonts.lato400,
      textAlign: "center",
      fontSize: 16,
      color: "black",
    },
    number: {
      ...theme.fonts.raleway700,
      display: "inline-block",
      fontSize: 36,
      textAlign: "center",
      paddingLeft: 10,
      paddingRight: 5,
      width: 60,
    },
    numberLong: {
      width: 90,
    },
    unit: {
      fontSize: 12,
      textTransform: "uppercase",
    },
    actions: {
      position: "absolute",
      top: "auto",
      right: "auto",
      bottom: 20,
      left: "50%",
      transform: "translateX(-50%)",
      [theme.breakpoints.up(600)]: {
        top: "50%",
        right: 40,
        bottom: "auto",
        left: "auto",
        transform: "translateY(-50%)",
      },
    },
    progressWrapper: {
      overflow: "hidden",
      position: "relative",
      height: props.mode === "registered" ? 0 : (props.buttonRef.current?.offsetHeight || "auto"),
      width: "auto",
      transition: theme.transitions.create(["height", "width"], {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
      [theme.breakpoints.up(600)]: {
        height: "auto",
        width: props.mode === "registered" ? 0 : (props.buttonRef.current?.offsetWidth || "auto"),
      },
    },
    progressIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)",
    },
  })
)

function getResult(count: number, unit: string): [number, string] {
  count = Math.floor(count)
  return [count, pluralize(unit, count)]
}

function getDiff(now: number, then: moment.Moment | Date | number): [number, string] {
  const secondsRemaining = moment(then, true).diff(moment(now, true), "seconds")
  if (secondsRemaining > oneYear) {
    return getResult(secondsRemaining / oneYear, "year")
  } else if (secondsRemaining >= oneDay) {
    return getResult(secondsRemaining / oneDay, "day")
  } else if (secondsRemaining >= oneHour) {
    return getResult(secondsRemaining / oneHour, "hour")
  } else if (secondsRemaining >= oneMinute) {
    return getResult(secondsRemaining / oneMinute, "minute")
  } else if (secondsRemaining >= oneSecond) {
    return getResult(secondsRemaining / oneSecond, "second")
  } else {
    return [0, ""]
  }
}

export default function ConventionCountdown(props: Props) {
  const rootRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const now = useNow({ precisionInMs: 1000 })

  const [number, unit] = getDiff(now.value, props.startAt)
  const isStarted = number === 0

  // @ts-ignore
  const { classes, cx } = useStyles({ ...props, rootRef, buttonRef, isStarted })

  function onRegisterClick(_event: MouseEvent) {
    if (props.onRegister) {
      props.onRegister()
    }
  }

  const isLongNumber = `${number}`.length === 3
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.root} ref={rootRef}>
          <div className={classes.text}>
            {(!isStarted && (
              <>
                <span>This event is starting in</span>
                <span className={cx(classes.number, isLongNumber && classes.numberLong)}>
                  {number}
                </span>
                <span className={classes.unit}>{unit}</span>
              </>
            )) || (
              <>
                <span>This event is starting</span>
                <span className={classes.number}>now</span>
              </>
            )}
          </div>
          <div className={classes.actions}>
            <div className={classes.progressWrapper}>
              {props.isRegisterButtonVisible && (
                <Button
                  ref={buttonRef}
                  variant="text"
                  color="primary"
                  disabled={props.mode !== "unregistered"}
                  onClick={onRegisterClick}>
                  {props.mode === "registered" && "Registered"}
                  {props.mode !== "registered" && "Register"}
                </Button>
              )}
              {(props.mode === "registering" || props.mode === "loading") && (
                <div className={classes.progressIcon}>
                  <CircularProgress size={24} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
