import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"
import { Current } from "lib/use-current"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import { getCurrentProviderId } from "utils/helpers"

interface Props {
  current: Current
  conventionId: string
}

const getConventionRegistration = async (props: Props) => {
  const { current, conventionId } = props

  const providerId = getCurrentProviderId(current)
  const url = `/v1/convention_providers/${providerId}/current_user/convention_registrations?filter[convention_id]=${conventionId}`

  const apiResponse = await sendConventionApiRequest({
    current,
    url,
    method: "GET",
  })

  const processedApiResponse = await processApiResponse<ConventionRegistration[]>(apiResponse)

  return processedApiResponse[0] ?? null
}

export default getConventionRegistration
