import getEnv from "lib/get-env-for-host"
import useCurrent from "lib/use-current"
import { useMemo } from "react"

const useHostEnv = () => {
  const current = useCurrent()
  return useMemo(() => {
    if (!current) return null
    if (!current.host) return null

    const env = getEnv(current.host)
    if (!env) return null

    return env
  }, [current?.host])
}

export default useHostEnv
