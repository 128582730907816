import {
  AuthDialog,
  ConventionLocationStyle,
  ConventionRegistrationState,
} from "app-constants/constants"
import useActiveTicketType from "hooks/conventions/useActiveTicketType"
import useCancelRegistrationDialog from "hooks/conventions/useCancelRegistrationDialog"
import useConventionPeopleCount from "hooks/conventions/useConventionPeopleCount"
import useConventionRegistrationState from "hooks/conventions/useConventionRegistrationState"
import useIsConventionClosedForRegistration from "hooks/conventions/useIsConventionClosedForRegistration"
import useRegisterInPersonDialog from "hooks/conventions/useRegisterInPersonDialog"
import useRegisterVirtualDialog from "hooks/conventions/useRegisterVirtualDialog"
import useAuthRedirect from "hooks/useAuthRedirect"
import useSearchParams from "hooks/useSearchParams"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import moment from "moment-timezone"
import React, { createContext, useContext } from "react"
import useAsyncEffect from "use-async-effect"
import { CurrentTimePhase, getCurrentTimePhase } from "utils/helpers"

export interface RegisterForConventionPayload {
  peopleCount?: number
}

interface IConventionRegistrationContext {
  openRegisterDialog: () => void
  openCancelDialog: () => void
  convention: Convention
  state: ConventionRegistrationState
  conventionRegistration?: ConventionRegistration | null
  isRegistered: boolean
}

// CONTEXT
export const ConventionRegistrationContext = createContext<IConventionRegistrationContext>(null!)

// PROVIDER

interface ConventionRegistrationProviderProps {
  children: React.ReactNode
  convention: Convention
}

export const ConventionRegistrationProvider = (props: ConventionRegistrationProviderProps) => {
  const { children, convention } = props

  const current = useCurrent()
  const searchParams = useSearchParams()
  const conventionIdToRegister = searchParams.get("conventionIdToRegister")

  const conventionId = convention.id
  const isUserLoggedIn = !!current?.user?.id
  const authRedirect = useAuthRedirect()

  const conventionRegistration = ConventionRegistration.useOne(() => {
    if (!isUserLoggedIn) return null
    if (!conventionId) return null

    return {
      filter: {
        convention_id: conventionId,
      },
      includes: ["ticket_type", "payment_intent"],
    }
  })

  const conventionPeopleCount = useConventionPeopleCount(convention)
  const isConventionClosedForRegistration = useIsConventionClosedForRegistration(convention)
  const activeTicketType = useActiveTicketType(convention, conventionRegistration.data)
  const conventionRegistrationState = useConventionRegistrationState({
    isClosedForRegistration: isConventionClosedForRegistration,
    convention,
    conventionPeopleCount,
    conventionRegistration: conventionRegistration.data,
    isLoadingConventionRegistration:
      conventionRegistration.data === undefined && conventionRegistration.isValidating,
    activeTicketType,
    paymentStatus: conventionRegistration.data?.payment_intent?.status ?? null,
  })

  const cancelRegistrationDialog = useCancelRegistrationDialog(() => {
    conventionRegistrationState.setState(ConventionRegistrationState.READY_FOR_REGISTRATION)
    conventionRegistration.mutate(undefined)
  })

  const registerVirtualDialog = useRegisterVirtualDialog((payload) => {
    conventionRegistrationState.setState(ConventionRegistrationState.REGISTERED)
    conventionRegistration.mutate(payload.conventionRegistration)
  })

  const registerInPersonDialog = useRegisterInPersonDialog((payload) => {
    conventionRegistrationState.setState(ConventionRegistrationState.REGISTERED)
    conventionRegistration.mutate(payload.conventionRegistration)
  })

  const openCancelDialog = () => {
    cancelRegistrationDialog.openDialog({
      convention,
      conventionRegistration: conventionRegistration.data!,
    })
  }

  const openRegisterDialog = (onSuccess?: () => void) => {
    if (conventionRegistrationState.isLoading) return

    authRedirect(
      () => {
        if (convention.location_style === ConventionLocationStyle.VIRTUAL) {
          registerVirtualDialog.openDialog(convention, onSuccess)

          return
        }

        if (convention.location_style === ConventionLocationStyle.INPERSON) {
          registerInPersonDialog.openDialog({
            convention,
            conventionPeopleCount,
            onSuccess,
          })

          return
        }
      },
      {
        dialogToOpen: {
          dialog:
            getCurrentTimePhase(moment(convention.start_at), moment(convention.end_at)) ===
            CurrentTimePhase.DURING
              ? AuthDialog.JOIN
              : AuthDialog.SIGN_UP_WITHOUT_PASSWORD,
          payload: {
            conventionIdToRegister: convention.id,
            allRegistrantTypes: convention.automatically_generated,
          },
        },
      },
    )
  }

  useAsyncEffect(async () => {
    if (conventionRegistrationState.state !== ConventionRegistrationState.READY_FOR_REGISTRATION)
      return
    if (convention.id !== conventionIdToRegister) return

    await searchParams.delete("conventionIdToRegister")

    openRegisterDialog()
  }, [conventionRegistrationState.state, conventionIdToRegister])

  const value: IConventionRegistrationContext = {
    state: conventionRegistrationState.state,
    isRegistered: conventionRegistrationState.isRegistered,
    convention,
    conventionRegistration: conventionRegistration.data,

    openRegisterDialog,
    openCancelDialog,
  }

  return (
    <ConventionRegistrationContext.Provider value={value}>
      {cancelRegistrationDialog.Dialog}
      {registerVirtualDialog.Dialog}
      {registerInPersonDialog.Dialog}
      {children}
    </ConventionRegistrationContext.Provider>
  )
}

// CONSUMERS

export const ConventionRegistrationConsumer = ConventionRegistrationContext.Consumer

export const useConventionRegistration = () => {
  const value = useContext(ConventionRegistrationContext)
  if (!value) throw "useConventionRegistration must be used inside ConventionRegistrationProvider"
  return value
}
