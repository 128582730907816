import {styled} from '@mui/system';
import { ConventionRegistrationState } from "app-constants/constants"
import ConventionCountdown from "components/ConventionCountdown"
import { ActivityIndicator } from "components/activity-indicator"
import GenericErrorBoundary from "components/generic-error-boundary"
import TopMacroEmbedNavigation from "components/navigation/TopMacroEmbedNavigation"
import TopMacroNavigation from "components/navigation/TopMacroNavigation"
import TopMicroEmbedNavigation from "components/navigation/TopMicroEmbedNavigation"
import TopMicroNavigation from "components/navigation/TopMicroNavigation"
import {
  ConventionRegistrationConsumer,
  ConventionRegistrationProvider,
} from "contexts/ConventionRegistrationContext"
import Convention from "models/convention-api/v1/Convention"
import ConventionBoolean from "models/convention-api/v1/ConventionBoolean"
import { useRouter } from "next/router"
import { ReactNode } from "react"
import { isInIframe } from "utils/helpers"
import {Box} from '@mui/material';

export type Layout = "macro" | "micro" | "macro-embed" | "micro-embed"

interface IMainLayout {
  children?: ReactNode
  conventionId?: string
  layout: Layout
}

const MainLayout = ({ children, layout }: IMainLayout) => {
  const router = useRouter()

  const conventionId = router.query.convention_id as string

  const { data: conventionBoolean } = ConventionBoolean.useOne(() => {
    if (!conventionId) return null

    return {
      filter: {
        convention_convention_id: conventionId,
        setting: "stage_enabled",
      },
    }
  })

  const { data: convention } = Convention.useOne(() => {
    if (!conventionId) return null

    return {
      filter: {
        id: conventionId,
      },
      includes: [
        "convention_registration_capacity",
        "convention_ticket_types",
        "convention_booleans",
      ],
    }
  })

  const stageEnabled = conventionBoolean?.value ?? false

  // check if the url has conventionId if it not, we skip isLoaded
  if (conventionId && !convention) return <ActivityIndicator />

  const isMicroOrMicroEmbed = layout === "micro" || layout === "micro-embed"

  const mainBody = (
    <_ConventionContainer
      automaticallyGeneratedConvention={convention?.automatically_generated}
      microLayout={isMicroOrMicroEmbed}
      id="main-content">
      <GenericErrorBoundary>
        {convention ? (
          <ConventionRegistrationProvider convention={convention}>
            <ConventionRegistrationConsumer>
              {(conventionRegistration) => (
                <ConventionCountdown
                  isRegisterButtonVisible={
                    conventionRegistration.state !== ConventionRegistrationState.CLOSED &&
                    conventionRegistration.state !== ConventionRegistrationState.AT_CAPACITY
                  }
                  startAt={convention.start_at}
                  mode={
                    (conventionRegistration.state === ConventionRegistrationState.LOADING &&
                      "loading") ||
                    (conventionRegistration.state === ConventionRegistrationState.REGISTERED_PAID &&
                      "registered") ||
                    (conventionRegistration.state === ConventionRegistrationState.REGISTERED &&
                      "registered") ||
                    (conventionRegistration.state === ConventionRegistrationState.LOADING &&
                      "registering") ||
                    "unregistered"
                  }
                  onRegister={() => conventionRegistration.openRegisterDialog()}
                />
              )}
            </ConventionRegistrationConsumer>
            {children}
          </ConventionRegistrationProvider>
        ) : (
          children
        )}
      </GenericErrorBoundary>
    </_ConventionContainer>
  )

  // @ts-ignore
  if (layout === "macro") {
    return (
      <>
        {!isInIframe() && <TopMacroNavigation />}
        {mainBody}
      </>
    )
  } else if (layout === "micro") {
    return (
      <_Container>
        <TopMicroNavigation convention={convention} stageEnabled={stageEnabled} />
        {mainBody}
      </_Container>
    )
  } else if (layout === "macro-embed") {
    return (
      <Box>
        <TopMacroEmbedNavigation />
        {mainBody}
      </Box>
    )
  } else if (layout === "micro-embed") {
    return (
      <_Container>
        <TopMicroEmbedNavigation convention={convention} stageEnabled={stageEnabled} />
        {mainBody}
      </_Container>
    )
  }

  return <ActivityIndicator />
}

export default MainLayout

interface MainProps {
  automaticallyGeneratedConvention: boolean
  microLayout: boolean
}

const _Container = styled(Box)(() => ({
  width: "100%",
  maxWidth: "1440px",
  margin: "0 auto",
}));

const _ConventionContainer = styled('main', {
  shouldForwardProp: propName =>  (
    !['automaticallyGeneratedConvention', 'microLayout'].includes(propName.toString())
  )
})<MainProps>(({automaticallyGeneratedConvention, microLayout, theme}) => ({
    ...(microLayout ? {
      marginLeft: "0",
      ...(!automaticallyGeneratedConvention && {
        [theme.breakpoints.up("lg")]: {
          marginLeft: "250px",
        },
      })
    } : {
      width: "100%",
      maxWidth: "1440px",
      margin: "0 auto",
    }),
  }));
