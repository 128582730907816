import { PaymentIntent } from "@stripe/stripe-js"
import { AuthDialog } from "app-constants/constants"
import ConfirmationPaymentDialog from "components/conventions/registration/dialogs/ConfirmationPaymentDialog"
import ConventionInPersonRegistrationDialog from "components/conventions/registration/dialogs/ConventionInPersonRegistrationDialog"
import useAuthRedirect from "hooks/useAuthRedirect"
import useSearchParams from "hooks/useSearchParams"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import moment from "moment-timezone"
import React, { useRef, useState } from "react"
import { CurrentTimePhase, getCurrentTimePhase } from "utils/helpers"

interface OnRegisterPayload {
  convention: Convention
  conventionRegistration: ConventionRegistration
  paymentIntent: PaymentIntent | null
}

interface OpenDialogPayload {
  convention: Convention
  conventionPeopleCount: number | null
  onSuccess?: () => void
}

const useRegisterInPersonDialog = (onRegister: (payload: OnRegisterPayload) => void) => {
  const authRedirect = useAuthRedirect()
  const [convention, setConvention] = useState<Convention | null>(null)
  const [conventionRegistration, setConventionRegistration] =
    useState<ConventionRegistration | null>(null)
  const [conventionPeopleCount, setConventionPeopleCount] = useState<number | null>(null)
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null)
  const onSuccessCallbackRef = useRef<(() => void) | null>(null)
  const searchParams = useSearchParams()

  const openDialog = (payload: OpenDialogPayload) => {
    authRedirect(
      () => {
        setConvention(payload.convention)
        setConventionPeopleCount(payload.conventionPeopleCount)
      },
      {
        dialogToOpen: {
          dialog:
            getCurrentTimePhase(
              moment(payload.convention.start_at),
              moment(payload.convention.end_at),
            ) === CurrentTimePhase.DURING
              ? AuthDialog.JOIN
              : AuthDialog.SIGN_UP_WITHOUT_PASSWORD,
          payload: {
            conventionIdToRegister: payload.convention.id,
            allRegistrantTypes: payload.convention.automatically_generated,
          },
        },
      },
    )
    onSuccessCallbackRef.current = payload.onSuccess
  }

  const handleRegister = async (payload: OnRegisterPayload) => {
    onRegister(payload)

    setConventionRegistration(payload.conventionRegistration)
    setPaymentIntent(payload.paymentIntent)

    if (onSuccessCallbackRef.current) onSuccessCallbackRef.current()
    if (!payload.paymentIntent) await handleClose(false)
  }

  const handleClose = async (clearSearchParams: boolean = true) => {
    setConvention(null)
    setConventionRegistration(null)
    setConventionPeopleCount(null)
    setPaymentIntent(null)
    onSuccessCallbackRef.current = null
    if (clearSearchParams) await searchParams.clear()
  }

  return {
    openDialog,
    Dialog: (
      <>
        {convention && !paymentIntent && (
          <ConventionInPersonRegistrationDialog
            convention={convention}
            conventionPeopleCount={conventionPeopleCount}
            onCancel={handleClose}
            onRegister={handleRegister}
          />
        )}
        {convention && conventionRegistration && paymentIntent && (
          <ConfirmationPaymentDialog
            convention={convention}
            conventionRegistration={conventionRegistration}
            paymentIntent={paymentIntent}
            onClose={handleClose}
          />
        )}
      </>
    ),
  }
}

export default useRegisterInPersonDialog
