import { loadStripe } from "@stripe/stripe-js"
import { Stripe } from "@stripe/stripe-js/types/stripe-js"
import { useBoolean } from "hooks/useBoolean"
import { useState } from "react"

const useStripeLoader = () => {
  const loaded = useBoolean(false)
  const loading = useBoolean(false)
  const [stripe, setStripe] = useState<Stripe | null>(null)

  const load: typeof loadStripe = async (...args) => {
    loading.on()
    try {
      const newStripe = await loadStripe(...args)
      setStripe(newStripe)

      loaded.on()
      loading.off()

      return newStripe
    } catch (error) {
      console.error(error)

      loading.off()
      return null
    }
  }

  return {
    stripe,
    load,
    loading: loading.value,
    loaded: loaded.value,
  }
}

export default useStripeLoader
