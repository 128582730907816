import { useEffect } from "react"
import { useState } from "react"

function roundToPrecision(value: number, precision = 1) {
  return Math.floor(value / precision) * precision
}

interface Props {
  precisionInMs?: number
}

/**
  Triggers a re-render and provides the epoch, ideal for displaying
  time-sensitive information since it doesn't waste computer cycles
  doing calculations when those results wouldn't render yet anyhow.

  As a bonus, it gets paused when the user navigates away from the
  page since any work done there also wouldn't render anyhow.

  An optional `precisionInMs` may be supplied to throttle re-renders.

  @example
  const now = useNow({
    precisionInMs: 1000
  })
  return now
*/
export default function useNow({ precisionInMs = 1 }: Props) {
  const [now, setNow] = useState(() => {
    const msSinceEpoch = +new Date()
    const rounded = roundToPrecision(msSinceEpoch, precisionInMs)
    return {
      startedAt: rounded,
      lastUpdatedAt: rounded,
      value: rounded,
      ticks: 0,
    }
  })

  useEffect(() => {
    const ref = { ...now }
    let handle: number

    function next() {
      const newValue = roundToPrecision(+new Date(), precisionInMs)
      if (newValue > ref.value) {
        ref.lastUpdatedAt = ref.value
        ref.value = newValue
        ref.ticks = ref.ticks < Number.MAX_VALUE ? ref.ticks + 1 : 0
        setNow({ ...ref })
      }
      handle = window.requestAnimationFrame(next)
    }

    handle = window.requestAnimationFrame(next)

    return () => {
      window.cancelAnimationFrame(handle)
    }
  }, [precisionInMs])

  return now
}
