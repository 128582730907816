import useAnycableUrl from "hooks/useAnycableUrl"
import useLastJsonMessage from "lib/react-use-websocket/useLastJsonMessage"
import useSubscribeChannel from "lib/react-use-websocket/useSubscribeChannel"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import { useState } from "react"
import useWebSocket from "react-use-websocket"

interface ChannelParsedData {
  identifier: "string"
  message: {
    people_count: number
  }
}

const useConventionPeopleCount = (
  convention: Convention | null,
  defaultPeopleCount?: null | number,
) => {
  const current = useCurrent()
  const identifier = convention ? createIdentifier(convention) : null
  const anycableURL = useAnycableUrl()
  const [peopleCount, setPeopleCount] = useState<number | null>(null)
  const websocket = useWebSocket(anycableURL, {
    queryParams: {
      ...(current?.authToken && { token: current.authToken }),
    },
  })

  useSubscribeChannel(websocket, identifier)
  useLastJsonMessage<ChannelParsedData>(websocket, (message) => {
    if (!isChannelParsedData(message)) return
    if (message.identifier !== identifier) return

    setPeopleCount(message.message.people_count ?? null)
  })

  return (
    peopleCount ??
    convention?.convention_registration_capacity?.people_count ??
    defaultPeopleCount ??
    null
  )
}

export default useConventionPeopleCount

const createIdentifier = (convention: Convention) =>
  '{"channel":"Realtime::ConventionConventionCapacityChannel","id":' + convention.id + "}"

const isChannelParsedData = (message: unknown): message is ChannelParsedData => {
  const anyMessage = message as any
  return (
    typeof anyMessage?.identifier === "string" &&
    typeof anyMessage?.message?.people_count === "number"
  )
}
