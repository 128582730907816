import { ConventionRegistrationStatus } from "app-constants/constants"
import ResourceModel from "models/convention-api/ResourceModel"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistrationPaymentIntent from "models/convention-api/v1/ConventionRegistrationPaymentIntent"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"

export default class ConventionRegistration extends ResourceModel {
  protected static basePath =
    "/v1/convention_providers/:provider_id/current_user/convention_registrations"
  protected static rels = {
    convention: () => ({
      type: Convention,
      from: "convention_convention_id",
      filterOn: "id",
    }),
    ticket_type: () => ({
      type: ConventionTicketType,
      from: "convention_ticket_type_id",
      filterOn: "id",
    }),
    payment_intent: () => ({
      type: ConventionRegistrationPaymentIntent,
      from: "id",
      filterOn: "convention_registration_id",
    }),
  }

  convention_user_id!: string
  convention_convention_id!: string
  convention_ticket_type_id!: string
  active!: boolean
  people_count!: null | number
  payment_status!: ConventionRegistrationStatus

  convention?: Convention
  ticket_type?: ConventionTicketType
  payment_intent?: ConventionRegistrationPaymentIntent
}
