import { styled, Typography } from "@mui/material"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { PaymentIntentResult } from "@stripe/stripe-js/types/stripe-js/stripe"
import { createPath, ROUTE } from "app-constants/routing"
import DialogButton from "components/conventions/registration/dialogs/DialogButton"
import { useBoolean } from "hooks/useBoolean"
import Convention from "models/convention-api/v1/Convention"
import React from "react"
import { pxToRem } from "utils/helpers"

interface DialogPaymentProps {
  convention: Convention
  loading: ReturnType<typeof useBoolean>
  onPayment: (result: PaymentIntentResult) => void
}

const DialogPayment = (props: DialogPaymentProps) => {
  const { loading, convention, onPayment } = props

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    loading.on()
    event.preventDefault()

    if (!stripe || !elements) return

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: createReturnUrl(convention),
      },
      redirect: "if_required",
    })

    onPayment(result)
    loading.off()
  }

  return (
    <_Form onSubmit={handleSubmit}>
      <PaymentElement />
      <DialogButton
        type="submit"
        loading={loading.value}
        children="Purchase Tickets"
        sx={{ marginTop: pxToRem(20) }}
      />
      <Typography component="p" variant="paragraphSmall" marginTop={pxToRem(10)}>
        By saving your card information, you allow VisitDays Corp to charge your card for future
        payments in accordance with their terms.
      </Typography>
    </_Form>
  )
}

export default DialogPayment

const _Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  height: "100%",
})

export const createReturnUrl = (convention: Convention) =>
  window.location.origin +
  createPath({ path: ROUTE.CONVENTION, params: { conventionId: convention.id } })
