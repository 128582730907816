import { DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION } from "app-constants/constants"
import { getConventionRegistrationError } from "errors/ConventionRegistrationError"
import googleAnalytics from "lib/googleAnalytics"
import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"
import { Current } from "lib/use-current"
import ConventionApiError from "models/convention-api/ConventionApiError"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import { getCurrentProviderId } from "utils/helpers"

interface PostConventionRegistrationParametars {
  current: Current
  conventionId: string
  peopleCount?: number
}

const postConventionRegistration = async (
  parameters: PostConventionRegistrationParametars,
): Promise<ConventionRegistration | never> => {
  const {
    current,
    conventionId,
    peopleCount = DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION,
  } = parameters

  try {
    const providerId = getCurrentProviderId(current)

    const response = await sendConventionApiRequest({
      current,
      method: "POST",
      url: `v1/convention_providers/${providerId}/current_user/convention_registrations`,
      body: JSON.stringify({
        data: {
          type: "convention_registration",
          attributes: {
            convention_convention_id: conventionId,
            people_count: peopleCount,
          },
        },
      }),
    })

    const processedResponse = await processApiResponse<ConventionRegistration>(response)

    googleAnalytics.pushConventionRegistrations([conventionId])

    return processedResponse
  } catch (error) {
    if (error instanceof ConventionApiError) {
      throw getConventionRegistrationError(error.code)
    }
    throw error
  }
}

export default postConventionRegistration
