import { LoadingButton } from "@mui/lab"
import { Box, Button, Dialog, styled, Typography } from "@mui/material"
import { CONVENTION_REGISTRATION_ERROR_MESSAGES } from "errors/ConventionRegistrationError"
import { useBoolean } from "hooks/useBoolean"
import useShowSnackbar from "hooks/useShowSnackbar"
import deleteConventionRegistration from "lib/deleteConventionRegistration"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import React from "react"
import { assertCurrent } from "utils/asserts"
import { pxToRem } from "utils/helpers"

interface Payload {
  convention: Convention
  conventionRegistration: ConventionRegistration
}

interface ConventionCancelRegistrationDialogProps {
  convention: Convention
  conventionRegistration: ConventionRegistration
  onClose: () => void
  onCancel: (payload: Payload) => void
}

const ConventionCancelRegistrationDialog = (props: ConventionCancelRegistrationDialogProps) => {
  const {
    convention,
    conventionRegistration,

    onCancel,
    onClose,
  } = props
  const current = useCurrent()
  const showSnackbar = useShowSnackbar()
  const isLoading = useBoolean(false)

  const handleCancel = async () => {
    isLoading.on()
    try {
      assertCurrent(current)
      await deleteConventionRegistration({ current, conventionRegistration })

      showSnackbar("Registration canceled successfully!", "success")
      onCancel({ convention, conventionRegistration })
    } catch (error) {
      console.error(error)
      showSnackbar(CONVENTION_REGISTRATION_ERROR_MESSAGES.CANNOT_BE_CANCELED, "error")
    } finally {
      isLoading.off()
    }
  }

  return (
    <_Dialog open>
      <_Container>
        <Typography component="h3" variant="header4">
          Registration Cancelation
        </Typography>
        <Typography variant="paragraphRegular">
          You’re about to cancel your registration for {convention.name}.
        </Typography>
        <_ActionsContainer>
          <Button
            onClick={onClose}
            variant="text"
            sx={{ color: "gray.darkest" }}
            disabled={isLoading.value}>
            GO BACK
          </Button>
          <LoadingButton
            loading={isLoading.value}
            onClick={handleCancel}
            variant="text"
            color="primary">
            YES, CANCEL
          </LoadingButton>
        </_ActionsContainer>
      </_Container>
    </_Dialog>
  )
}

export default ConventionCancelRegistrationDialog

const _Dialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    position: "relative",

    width: "100%",
    minWidth: pxToRem(300),
    maxWidth: pxToRem(360),

    borderRadius: pxToRem(10),

    backgroundColor: theme.palette.gray.lightest,
    boxShadow: `0 ${pxToRem(4)} ${pxToRem(4)} rgba(0, 0, 0, 0.25), 0 0 ${pxToRem(
      4,
    )} rgba(0, 0, 0, 0.12)`,

    overflow: "hidden",
  },
})) as typeof Dialog

const _Container = styled(Box)({
  display: "flex",
  flexFlow: "nowrap column",
  gap: pxToRem(30),

  padding: pxToRem(30),
  paddingBottom: pxToRem(14),

  textAlign: "center",
}) as typeof Box

const _ActionsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "row nowrap",

  marginTop: pxToRem(30),
  marginLeft: pxToRem(-8),
  marginRight: pxToRem(-8),
}) as typeof Box
