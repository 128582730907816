import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"
import { Current } from "lib/use-current"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import { getCurrentProviderId } from "utils/helpers"

interface DeleteConventionRegistrationParameters {
  current: Current
  conventionRegistration: ConventionRegistration
}

const deleteConventionRegistration = async (parameters: DeleteConventionRegistrationParameters) => {
  const { current, conventionRegistration } = parameters

  const providerId = getCurrentProviderId(current)

  const response = await sendConventionApiRequest({
    current,
    method: "DELETE",
    url: `v1/convention_providers/${providerId}/current_user/convention_registrations/${conventionRegistration.id}`,
  })

  return processApiResponse(response)
}

export default deleteConventionRegistration
