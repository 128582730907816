import { ButtonProps, SvgIcon } from "@mui/material"
import Button from "@mui/material/Button"
import { styled } from "@mui/system"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"

export interface LeftNavigationMenuItem {
  path: string
  Icon: typeof SvgIcon
  label: React.ReactNode
  isEnabled?: boolean
}

interface LeftNavigationMenuProps {
  className?: string
  menuItems: LeftNavigationMenuItem[]
}

const LeftNavigationMenu = (props: LeftNavigationMenuProps) => {
  const { className, menuItems } = props

  const router = useRouter()
  const pathWithoutQuery = router.asPath.split("?")[0]

  return (
    <CustomList className={className}>
      {menuItems.map((link) => {
        if (link.isEnabled === false) return null

        const isActive = link.path === pathWithoutQuery
        return (
          <CustomListItem key={link.path}>
            <Link href={link.path} passHref legacyBehavior>
              <CustomNavLink variant="text" isActive={isActive} startIcon={<link.Icon />}>
                {link.label}
              </CustomNavLink>
            </Link>
          </CustomListItem>
        )
      })}
    </CustomList>
  )
}

export default LeftNavigationMenu

const CustomList = styled("ul")({
  padding: 0,
  listStyleType: "none",
})

const CustomListItem = styled("li")({})

interface NavLinkProps extends ButtonProps {
  isActive: boolean
}

const CustomNavLink = styled(Button, {
  shouldForwardProp: (propName) => propName !== "isActive",
})<NavLinkProps>(({ theme, isActive }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  padding: "23px 41px",
  color: theme.palette.gray.darkest,
  borderRadius: 0,

  "&.MuiButton-root.MuiButton-root": {
    textTransform: "capitalize",
  },
  ".MuiButton-startIcon": {
    color: theme.palette.gray.darker,
  },

  "&:hover": {
    backgroundColor: theme.palette.primary.lighter,
  },

  ...(isActive
    ? {
        ...theme.fonts.raleway700,
      }
    : {
        ...theme.fonts.raleway500,
      }),
}))
