import CloseIcon from "@mui/icons-material/Close"
import { Box, styled, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { pxToRem } from "utils/helpers"

interface DialogHeaderProps {
  onClose: () => void
  closeButtonDisabled?: boolean
}

const DialogHeader = (props: DialogHeaderProps) => {
  const { onClose, closeButtonDisabled = false } = props

  return (
    <_Container>
      <Typography component="h3" variant="header3">
        Event Registration
      </Typography>
      <_CloseIconButton
        aria-label="Close Event Registration Modal"
        disabled={closeButtonDisabled}
        onClick={onClose}>
        <CloseIcon />
      </_CloseIconButton>
    </_Container>
  )
}

export default DialogHeader

const _Container = styled(Box)({
  position: "sticky",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "nowrap row",
  padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
}) as typeof Box

const _CloseIconButton = styled(IconButton)({}) as unknown as typeof IconButton
