import hasTicketTypes from "lib/conventions/hasTicketTypes"
import isPaid from "lib/conventions/isPaid"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import moment from "moment-timezone"

const getActiveTicketType = (
  convention: Convention,
  conventionRegistration?: ConventionRegistration | null,
): null | ConventionTicketType => {
  if (!isPaid(convention)) return null
  if (!hasTicketTypes(convention)) return null
  if (conventionRegistration?.ticket_type) return conventionRegistration.ticket_type

  const now = moment()
  const ticketTypes = convention.convention_ticket_types

  let activeTicketType: null | ConventionTicketType = null
  ticketTypes.forEach((ticketType) => {
    const endAt = moment(ticketType.end_at)

    if (endAt.isBefore(now)) return

    if (activeTicketType && endAt.isAfter(activeTicketType.end_at)) return

    activeTicketType = ticketType
  })

  return activeTicketType
}

export default getActiveTicketType
