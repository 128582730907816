enum ConventionRegistrationErrorCode {
  // From API
  TAKEN = "taken",
  OVER_CAPACITY = `can't be over the convention capacity`,
  FULL = "cannot be set because convention is full",
  CLOSED = "is closed for new registrations",

  // Custom
  CANNOT_BE_COMPLETED = "CANNOT_BE_COMPLETED",
  CANNOT_BE_CANCELED = "CANNOT_BE_CANCELED",
}

type ConventionRegistrationMessage = string

export const CONVENTION_REGISTRATION_ERROR_MESSAGES: Record<
  ConventionRegistrationErrorCode,
  ConventionRegistrationMessage
> = {
  // From API
  [ConventionRegistrationErrorCode.TAKEN]:
    "You are already registered for this convention! Please refresh the page.",
  [ConventionRegistrationErrorCode.OVER_CAPACITY]:
    "Your registration cannot be completed due to insufficient ticket quantity.",
  [ConventionRegistrationErrorCode.FULL]:
    "Your registration cannot be completed due to insufficient ticket quantity.",
  [ConventionRegistrationErrorCode.CLOSED]: "Your registration cannot be completed!",

  // Custom
  [ConventionRegistrationErrorCode.CANNOT_BE_COMPLETED]: "Your registration cannot be completed!",
  [ConventionRegistrationErrorCode.CANNOT_BE_CANCELED]:
    "Cannot cancel registration for the convention!",
} as const

export class ConventionRegistrationError extends Error {
  constructor(
    public code: ConventionRegistrationErrorCode,
    message: ConventionRegistrationMessage,
  ) {
    super(message)
  }
}

export const getConventionRegistrationError = (code: string) => {
  const hasCode = Object.values(ConventionRegistrationErrorCode).includes(
    code as ConventionRegistrationErrorCode,
  )
  const validCode = hasCode
    ? (code as ConventionRegistrationErrorCode)
    : ConventionRegistrationErrorCode.CANNOT_BE_COMPLETED

  return new ConventionRegistrationError(
    validCode,
    CONVENTION_REGISTRATION_ERROR_MESSAGES[validCode],
  )
}
