import { MenuItem, Select, SelectProps, styled, Typography } from "@mui/material"
import { Box } from "@mui/material"
import pluralize from "pluralize"
import React from "react"
import { pxToRem } from "utils/helpers"

interface DialogTicketsProps {
  ticketsLeft: number

  minTickets: number
  maxTickets: number
  tickets: number
  onChangeTickets: SelectProps<number>["onChange"]
  disabled?: boolean
  ticketsLeftMessage: boolean
}

const DialogTickets = (props: DialogTicketsProps) => {
  const {
    ticketsLeft,
    minTickets,
    maxTickets,
    tickets,
    onChangeTickets,
    disabled,
    ticketsLeftMessage,
  } = props

  const generatedTickets = generateTickets(minTickets, maxTickets)
  const canSelectTickets = !disabled
  return (
    <_Container>
      {canSelectTickets && (
        <_LeftContainer>
          <Typography
            component="label"
            variant="header6"
            id="convention-registration-dialogs-tickets-input-label"
            htmlFor="convention-registration-dialogs-tickets-select">
            Tickets
          </Typography>
          <Typography component="p" variant="paragraphItalic" color="gray.darker">
            This number should reflect you + the number of guests you’re bringing to the event.
          </Typography>
          {ticketsLeftMessage && ticketsLeft <= maxTickets && (
            <Typography component="p" variant="paragraphItalic" color="error.main">
              Only {ticketsLeft} {pluralize("ticket", ticketsLeft, false)} remaining.
            </Typography>
          )}
        </_LeftContainer>
      )}
      <_RightContainer>
        {disabled && (
          <Typography
            component="label"
            id="convention-registration-dialogs-tickets-input-label"
            htmlFor="convention-registration-dialogs-tickets-select"
            variant="paragraphSmall">
            Tickets
          </Typography>
        )}
        <_Select
          disabled={disabled}
          variant="filled"
          labelId="convention-registration-dialogs-tickets-select-label"
          id="convention-registration-dialogs-tickets-select"
          value={tickets}
          onChange={onChangeTickets}>
          {generatedTickets.map((ticket) => (
            <_MenuItem key={ticket} value={ticket}>
              {ticket}
            </_MenuItem>
          ))}
        </_Select>
      </_RightContainer>
    </_Container>
  )
}

export default DialogTickets

const _Container = styled(Box)({
  display: "flex",
  flexFlow: "nowrap row",
  gap: pxToRem(40),
}) as typeof Box

const _LeftContainer = styled(Box)({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
  gap: pxToRem(5),
  width: "100%",
}) as typeof Box

const _RightContainer = styled(Box)({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(5),

  maxWidth: pxToRem(140),
  width: "100%",
}) as typeof Box

const _Select = styled(Select)(({ theme }) => ({
  width: pxToRem(140),
  height: pxToRem(60),
  background: theme.palette.gray.light,
  borderRadius: pxToRem(10),
  "&::before, &::after": {
    display: "none",
  },
  "&.Mui-disabled > .MuiSvgIcon-root": {
    display: "none",
  },
  ".MuiSelect-select": {
    padding: `${pxToRem(19)} ${pxToRem(15)}`,
    borderRadius: pxToRem(10),
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.gray.darkest,
    right: pxToRem(14),
  },
})) as unknown as typeof Select

const _MenuItem = styled(MenuItem)({}) as unknown as typeof MenuItem

const generateTickets = (minTickets: number, maxTickets: number) => {
  const tickets: number[] = []

  for (let ticket = minTickets; ticket <= maxTickets; ticket++) {
    tickets.push(ticket)
  }

  return tickets
}
