import FlareIcon from "@mui/icons-material/Flare"
import HomeIcon from "@mui/icons-material/Home"
import ViewComfyIcon from "@mui/icons-material/ViewComfy"
import ViewCompactIcon from "@mui/icons-material/ViewCompact"
import { Box, Theme } from "@mui/material"
import { useMediaQuery } from "@mui/material"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/system"
import { AuthDialog, ConventionLocationStyle, NAVIGATION_HEIGHT } from "app-constants/constants"
import { createPath, ROUTE } from "app-constants/routing"
import NavigationLogo from "components/NavigationLogo"
import AccountLink from "components/account/AccountLink"
import LeftNavigationMenu, {
  LeftNavigationMenuItem,
} from "components/navigation/LeftNavigationMenu"
import { NavLinkProps } from "components/navigation/NavLink"
import TopNavigationContainer from "components/navigation/TopNavigationContainer"
import TopNavigationHamburgerMenu from "components/navigation/TopNavigationHamburgerMenu"
import { TopNavigationHorizontalMenu } from "components/navigation/TopNavigationHorizontalMenu"
import TopNavigationVerticalMenu from "components/navigation/TopNavigationVerticalMenu"
import { useExhibitorCopyText } from "hooks/exhibitors/useExhibitorsCopyText"
import useAuthDialog from "hooks/useAuthDialog"
import { useUser } from "hooks/useUser"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import { useRouter } from "next/router"
import pluralize from "pluralize"
import React, { useMemo } from "react"

interface TopMicroNavigationProps {
  convention: Convention
  stageEnabled: boolean
}

const TopMicroNavigation = (props: TopMicroNavigationProps) => {
  const { stageEnabled, convention } = props
  const sessionEnabled = convention!.location_style !== ConventionLocationStyle.INPERSON
  const exhibitorsCopyText = useExhibitorCopyText()
  const current = useCurrent()
  const user = useUser()
  const providerConfig = current?.config
  const router = useRouter()
  const authDialog = useAuthDialog()

  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))

  const conventionId = router.query.convention_id as string

  const authNavLinks: NavLinkProps[] = useMemo(
    () => [
      {
        href: "/",
        text: "Sign In",
        variant: "outlined",
        onClick: (event) => {
          event.preventDefault()
          event.stopPropagation()
          authDialog.set(AuthDialog.JOIN, { preamble: '', dialogHeader: 'Sign In'})
        },
      },
      {
        href: "/",
        text: "Create an Account",
        variant: "contained",
        onClick: (event) => {
          event.preventDefault()
          event.stopPropagation()
          authDialog.set(AuthDialog.SIGN_UP)
        },
      },
    ],
    [],
  )

  const generalNavLinks: NavLinkProps[] = useMemo(
    () => [
      {
        href: createPath({ path: ROUTE.CONVENTIONS }),
        text: providerConfig?.expo_copy_text ?? "Conventions",
      },
      {
        href: createPath({ path: ROUTE.EXHIBITORS }),
        text: exhibitorsCopyText!.plural,
      },
      ...(providerConfig?.people_index
        ? [
            {
              href: createPath({ path: ROUTE.PEOPLE }),
              text: "People",
            },
          ]
        : []),
      {
        href: createPath({ path: ROUTE.VIDEOS }),
        text: "Videos",
      },
      {
        href: createPath({ path: ROUTE.ITINERARIES }),
        text: "My Schedule",
        onClick: (event) => {
          if (!user) {
            event.preventDefault()
            event.stopPropagation()
            authDialog.set(AuthDialog.JOIN, { preamble: '', dialogHeader: 'Sign In'})
          }
        },
      },
    ],
    [providerConfig?.expo_copy_text, providerConfig?.people_index, exhibitorsCopyText, user],
  )

  const leftMenuItems: LeftNavigationMenuItem[] = [
    {
      path: createPath({ path: ROUTE.CONVENTION, params: { conventionId } }),
      label: providerConfig.nav_launchpad_copy_text,
      Icon: HomeIcon,
    },
    {
      path: createPath({ path: ROUTE.CONVENTION_STAGE, params: { conventionId } }),
      label: providerConfig.nav_stage_copy_text,
      isEnabled: stageEnabled,
      Icon: FlareIcon,
    },
    {
      path: createPath({ path: ROUTE.CONVENTION_SESSIONS, params: { conventionId } }),
      label: pluralize(providerConfig.session_copy_text),
      isEnabled: sessionEnabled,
      Icon: ViewCompactIcon,
    },
    {
      path: createPath({ path: ROUTE.CONVENTION_EXHIBITORS, params: { conventionId } }),
      label: exhibitorsCopyText?.plural,
      Icon: ViewComfyIcon,
    },
  ]

  if (isWideScreen) {
    return (
      <>
        <NavContent
          generalNavLinks={generalNavLinks}
          authNavLinks={authNavLinks}
          leftMenuItemsHidden={convention.automatically_generated}
          leftMenuItems={leftMenuItems}
          isWideScreen={isWideScreen}
          user={user}
          conventionName={convention.name}
        />
        {!convention.automatically_generated && (
          <_LeftNavigation component="nav">
            <_LeftNavigationContainer role="region" aria-label="Left Navigation">
              <_LeftNavigationConventionName component="h3">
                {convention.name}
              </_LeftNavigationConventionName>
                <_LeftNavigationMenu menuItems={leftMenuItems} />
            </_LeftNavigationContainer>
            <_LeftNavigationRightDivider aria-hidden={true} />
            <_LeftNavigationBackground aria-hidden={true} />
          </_LeftNavigation>
        )}
      </>
    )
  }

  return (
    <NavContent
      generalNavLinks={generalNavLinks}
      authNavLinks={authNavLinks}
      leftMenuItems={leftMenuItems}
      isWideScreen={isWideScreen}
      leftMenuItemsHidden={convention.automatically_generated}
      user={user}
      conventionName={convention.name}
    />
  )
}

export default TopMicroNavigation

interface NavigationContentProps {
  generalNavLinks: NavLinkProps[]
  authNavLinks: NavLinkProps[]
  leftMenuItemsHidden?: boolean
  leftMenuItems: LeftNavigationMenuItem[]
  isWideScreen: boolean
  user: any
  conventionName: string
}

const NavContent = (props: NavigationContentProps) => {
  const { generalNavLinks, authNavLinks, leftMenuItemsHidden = false, leftMenuItems, isWideScreen, user, conventionName } = props

  return (
    <_TopNavigationContainer>
      {!isWideScreen && (
        <>
          <TopNavigationHamburgerMenu>
            <TopNavigationVerticalMenu menuItems={generalNavLinks} />
            {!user && <TopNavigationVerticalMenu menuItems={authNavLinks} />}
            <_CurrentlyViewing>Currently viewing</_CurrentlyViewing>
            <_ConventionName component="h3">{conventionName}</_ConventionName>
            {!leftMenuItemsHidden && <_TopNavigationLeftMenu menuItems={leftMenuItems} />}
          </TopNavigationHamburgerMenu>
        </>
      )}

      <_TopNavigationLogo />

      {isWideScreen && (
        <_TopNavigationMainMenu>
          <TopNavigationHorizontalMenu menuItems={generalNavLinks} />
          {!user && <_TopNavigationHorizontalAuthMenu menuItems={authNavLinks} />}
        </_TopNavigationMainMenu>
      )}

      {user && <AccountLink />}
    </_TopNavigationContainer>
  )
}

const _TopNavigationHorizontalAuthMenu = styled(TopNavigationHorizontalMenu)({
  marginLeft: "32px",
  ".MuiButton-root.MuiButton-root": {
    borderRadius: "10px",
  },
})

const _TopNavigationContainer = styled(TopNavigationContainer)({
  display: "flex",
  width: "100%",
  height: "100%",
  flexFlow: "nowrap row",
  justifyContent: "space-between",
  alignItems: "center",
})

const _TopNavigationMainMenu = styled(Box)({
  display: "flex",
  flexFlow: "nowrap row",
  alignItems: "center",
  marginLeft: "auto",
  width: "auto",
})

const _TopNavigationLogo = styled(NavigationLogo)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.up("lg")]: {
    margin: 0,
  },
}))

const _TopNavigationLeftMenu = styled(LeftNavigationMenu)({
  width: "100%",
  marginTop: "40px",
  ".MuiButton-root.MuiButton-root": {
    paddingLeft: "20px",
  },
})

const _CurrentlyViewing = styled(Typography)(({ theme }) => ({
  margin: "25px 0 0 20px",
  padding: 0,
  color: theme.palette.gray.dark,
  ...theme.fonts.lato400,
  fontStyle: "italic",
  fontSize: "16px",
  lineHeight: "19px",
})) as typeof Typography

const _ConventionName = styled(Typography)(({ theme }) => ({
  margin: "10px 0 25px 20px",
  padding: 0,
  ...theme.fonts.lato400,
  fontSize: "20px",
  lineHeight: "24px",
})) as typeof Typography

const _LeftNavigationConventionName = styled(_ConventionName)({
  margin: "40px 0 0 0",
  padding: "0 41px 0 41px",
  width: "100%",
}) as typeof Typography

const _LeftNavigation = styled(Box)({
  pointerEvents: "auto",
  alignItems: "stretch",
  flexBasis: "auto",
  flexDirection: "column",
  flexShrink: 0,
  height: "100vh",
  position: "fixed",
  zIndex: 49,
  width: "250px",
})

const _LeftNavigationBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: -NAVIGATION_HEIGHT,
  right: 0,
  bottom: 0,
  left: "-100vh",
  backgroundColor: theme.palette.gray.lightest,
  zIndex: -1,
}))

const _LeftNavigationContainer = styled(Box)({
  display: "flex",
  flexFlow: "nowrap column",
  alignItems: "center",
  width: "100%",
  height: "100%",
})

const _LeftNavigationRightDivider = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "calc(100vh + 100%)",
  top: -NAVIGATION_HEIGHT,
  right: 0,
  bottom: 0,
  borderRight: `1px solid ${theme.palette.gray.light}`,
}))

const _LeftNavigationMenu = styled(LeftNavigationMenu)({
  width: "100%",
  marginTop: "40px",
})
