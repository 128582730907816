import { ConventionBooleanSetting } from "app-constants/constants"
import ResourceModel from "models/convention-api/ResourceModel"

export default class ConventionBoolean extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_booleans"

  convention_convention_id!: string
  setting!: ConventionBooleanSetting
  value!: boolean
}
