import { Box, styled } from "@mui/material"
import { pxToRem } from "utils/helpers"

const DialogContent = styled(Box)({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(40),
  padding: pxToRem(20),
  overflowY: "auto",
}) as typeof Box

export default DialogContent
