import { useEffect } from "react"
import useWebSocket from "react-use-websocket"

type WebSocket = ReturnType<typeof useWebSocket>
type Callback<T> = (data: T | null) => void

const useLastJsonMessage = <T>(websocket: WebSocket, callback: Callback<T>) => {
  useEffect(() => {
    callback(websocket.lastJsonMessage as T)
  }, [callback, websocket.lastJsonMessage])
}

export default useLastJsonMessage
