import useHostEnv from "hooks/useHostEnv"

const useAnycableUrl = () => {
  const hostEnv = useHostEnv()

  if (!hostEnv?.NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL) return null

  return `${hostEnv.NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL}/cable`
}

export default useAnycableUrl
