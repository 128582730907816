import ResourceModel from "models/convention-api/ResourceModel"
import Convention from "models/convention-api/v1/Convention"

export default class ConventionRegistrationCapacity extends ResourceModel {
  protected static basePath =
    "/v1/convention_providers/:provider_id/convention_registration_capacities"
  protected static rels = {
    convention: () => ({
      type: Convention,
      from: "convention_convention_id",
      filterOn: "id",
    }),
  }

  convention_convention_id!: string
  people_count!: number

  convention?: Convention
}
