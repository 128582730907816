import { StripePaymentStatus } from "app-constants/constants"
import ResourceModel from "models/convention-api/ResourceModel"

export default class ConventionRegistrationPaymentIntent extends ResourceModel {
  protected static basePath =
    "/v1/convention_providers/:provider_id/current_user/convention_registrations/payment_intents"

  client_secret!: string
  public_key!: string
  account_identifier!: string
  convention_registration_id!: string
  status!: StripePaymentStatus
  amount!: number
}
