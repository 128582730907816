import getActiveTicketType from "lib/conventions/getActiveTicketType"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import { useState } from "react"
import { useInterval } from "usehooks-ts"

const useActiveTicketType = (
  convention: Convention,
  conventionRegistration?: ConventionRegistration | null,
) => {
  const [activeTicketType, setActiveTicketType] = useState<ConventionTicketType | null>(() =>
    getActiveTicketType(convention),
  )
  useInterval(() => {
    setActiveTicketType(getActiveTicketType(convention, conventionRegistration))
    // Check every 5 seconds
  }, 5000)

  return activeTicketType
}

export default useActiveTicketType
