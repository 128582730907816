import { Box, styled, Typography } from "@mui/material"
import { pxToRem } from "utils/helpers"

const DialogAdditionalText = () => (
  <_Container>
    <Typography component="p" variant="paragraphRegular" marginTop={pxToRem(10)}>
      Upon registering, we’ll send you a confirmation and occasional reminders.
    </Typography>
  </_Container>
)

export default DialogAdditionalText

const _Container = styled(Box)(({ theme }) => ({
  paddingTop: pxToRem(20),
  borderTop: `${pxToRem(1)} solid ${theme.palette.gray.light}}`,
})) as typeof Box
