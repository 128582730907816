import { Box, styled, Typography } from "@mui/material"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import moment from "moment-timezone"
import React from "react"
import getFormattedPrice from "utils/getFormattedPrice"
import { pxToRem } from "utils/helpers"

interface TicketTypeCardProps {
  ticketType: ConventionTicketType
  className?: string
}

const TicketTypeCard = (props: TicketTypeCardProps) => {
  const { ticketType, className } = props
  return (
    <_Container className={className}>
      <Typography variant="header6" marginBottom={pxToRem(3)}>
        {ticketType.name}
      </Typography>
      <Typography variant="paragraphRegular">
        {getFormattedPrice(ticketType.cost_usd_cents)}
      </Typography>
      <Typography variant="paragraphItalic" color="gray.darker">
        Ends on {moment(ticketType.end_at).format("MMM DD, YYYY")}
      </Typography>
    </_Container>
  )
}

export default TicketTypeCard

const _Container = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(5),
})) as typeof Box
